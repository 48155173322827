import React from "react";
import { Fond } from "../../../../structure/PointDansLigne";

export type SelecteurFondProps = {
  fond: Fond;
  onChange: (fond: Fond) => void;
};

export default function SelecteurFond(props: SelecteurFondProps) {
  const changeFond = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.stopPropagation();

    const newFond = parseInt(event.target.value) as Fond;
    props.onChange(newFond);
  };

  const getListeFond = (): Array<Fond> => {
    return [
      Fond.Vide,
      Fond.CentreHaut,
      Fond.GaucheHaut,
      Fond.DroiteHaut,
      Fond.GaucheDroiteHaut,
      Fond.CentreBas,
      Fond.CentreHautBas,
      Fond.DroiteHautCentreBas,
      Fond.GaucheDroiteHautCentreBas,
      Fond.GaucheBas,
      Fond.CentreHautGaucheBas,
      Fond.GaucheHautBas,
      Fond.GaucheDroiteHautGaucheBas,
      Fond.DroiteBas,
      Fond.DroiteHautBas,
      Fond.GaucheDroiteHautDroiteBas,
      Fond.GaucheDroitePetitMilieuHautDroiteBas,
      Fond.GaucheDroiteBas,
      Fond.CentreHautGaucheDroiteBas,
      Fond.GaucheHautGaucheDroiteBas,
      Fond.DroiteHautGaucheDroiteBas,
      Fond.GaucheDroiteHautGaucheDroiteBas,
      Fond.GaucheDroiteHautCentreMilieuGaucheDroiteBas,
      Fond.GaucheDroiteHautDroitePetitMilieuBas,
    ];
  };

  return (
    <select onChange={changeFond} defaultValue={props.fond}>
      {getListeFond().map((fond) => (
        <option value={fond} key={fond.toLocaleString()}>
          {Fond[fond]}
        </option>
      ))}
    </select>
  );
}
