import Region from "../structure/Region";
import Ligne from "../structure/Ligne";
import { IVersionManager } from "./VersionManager";
import { TypeLigne } from "../structure/TypeLigne";
import Gare from "../structure/Gare";
import { Couleur } from "../structure/Couleur";
import GareDansLigne from "../structure/GareDansLigne";

export class DataLoader {
  private PathData: string = "data/";
  private _versionManager: IVersionManager;

  public constructor(versionManager: IVersionManager) {
    this._versionManager = versionManager;
  }

  private async getFile(path: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      let xhr = new XMLHttpRequest();
      xhr.open("GET", path);
      xhr.onload = () => {
        if (xhr.status === 200) resolve(xhr.responseText);
        if (xhr.status === 404) reject("File not found");
      };
      xhr.send();
    });
  }

  private async readLigneFile(region: Region): Promise<Region> {
    let contentFile = this.getFile(
      this.PathData + region.dossierId + "/Lignes.csv"
    );
    return contentFile.then((content: string) => {
      let lignes = content.split("\n");
      let i = 0;
      let pos: { [nom: string]: number } = {};
      for (let ligne of lignes) {
        let cellules = ligne.trim().split(";");
        if (ligne === "" || cellules.length === 0) continue;
        if (i === 0) {
          //Entêtes
          cellules.forEach((value: string, index: number) => {
            pos[value] = index;
          });
        } else {
          //Data
          region.addLigne(
            new Ligne(
              cellules[pos["idExterne"]],
              cellules[pos["nom"]],
              TypeLigne[cellules[pos["type"]] as keyof typeof TypeLigne],
              parseInt(cellules[pos["ordre"]]),
              cellules[pos["couleur"]],
              this._versionManager.getVersion(
                parseInt(cellules[pos["vCreation"]])
              ),
              this._versionManager.getVersion(parseInt(cellules[pos["vMaj"]])),
              this._versionManager.getVersion(
                parseInt(cellules[pos["vSuppression"]])
              )
            )
          );
        }
        i++;
      }
      return region;
    });
  }

  private async readGareFile(region: Region): Promise<Region> {
    let contentFile = this.getFile(
      this.PathData + region.dossierId + "/Gares.csv"
    );
    return contentFile.then((content: string) => {
      let lignes = content.split("\n");
      let i = 0;
      let pos: { [nom: string]: number } = {};
      for (let ligne of lignes) {
        let cellules = ligne.trim().split(";");
        if (ligne === "" || cellules.length === 0) continue;
        if (i === 0) {
          //Entêtes
          cellules.forEach((value: string, index: number) => {
            pos[value] = index;
          });
        } else {
          //Data
          region.addGare(
            new Gare(
              cellules[pos["id"]],
              cellules[pos["idExterne"]],
              cellules[pos["nom"]],
              cellules[pos["exploitant"]],
              parseFloat(cellules[pos["latitude"]]),
              parseFloat(cellules[pos["longitude"]]),
              Couleur[cellules[pos["couleur"]] as keyof typeof Couleur],
              Couleur[
                cellules[pos["couleurEvolution"]] as keyof typeof Couleur
              ],
              this._versionManager.getVersion(
                parseInt(cellules[pos["vCreation"]])
              ),
              this._versionManager.getVersion(parseInt(cellules[pos["vMaj"]])),
              this._versionManager.getVersion(
                parseInt(cellules[pos["vSuppression"]])
              )
            )
          );
        }
        i++;
      }
      return region;
    });
  }

  private async readCoordonneesFile(region: Region): Promise<Region> {
    let contentFile = this.getFile(
      this.PathData + region.dossierId + "/Coordonnees.csv"
    );
    return contentFile
      .then((content: string) => {
        let lignes = content.split("\n");
        let i = 0;
        let pos: { [nom: string]: number } = {};
        for (let ligne of lignes) {
          let cellules = ligne.trim().split(";");
          if (ligne === "" || cellules.length === 0) continue;
          if (i === 0) {
            //Entêtes
            cellules.forEach((value: string, index: number) => {
              pos[value] = index;
            });
          } else {
            const idGare = cellules[pos["idGare"]];
            const latitude = parseFloat(cellules[pos["latitude"]]);
            const longitude = parseFloat(cellules[pos["longitude"]]);

            const gare = region.getGare(idGare);

            if (gare) {
              gare.addCoordonnees({ latitude, longitude });
            }
          }
          i++;
        }
        return region;
      })
      .catch((reason) => region);
  }

  private async readGaresDansLigneFile(region: Region): Promise<Region> {
    let contentFile = this.getFile(
      this.PathData + region.dossierId + "/GaresDansLigne.csv"
    );
    return contentFile.then((content: string) => {
      let lignes = content.split("\n");
      let i = 0;
      let pos: { [nom: string]: number } = {};
      for (let ligne of lignes) {
        let cellules = ligne.trim().split(";");
        if (ligne === "" || cellules.length === 0) continue;
        if (i === 0) {
          //Entêtes
          cellules.forEach((value: string, index: number) => {
            pos[value] = index;
          });
        } else {
          //Data
          let ligne = region.getLigne(cellules[pos["idLigne"]]);
          if (ligne === undefined) continue;
          let gare = region.getGare(cellules[pos["idGare"]]);
          if (gare === undefined) continue;
          region.addGaresDansLigne(
            new GareDansLigne(
              gare,
              ligne,
              cellules[pos["nom"]] === "" ? null : cellules[pos["nom"]],
              cellules[pos["surTitre"]] === ""
                ? null
                : cellules[pos["surTitre"]],
              cellules[pos["sousTitre"]] === ""
                ? null
                : cellules[pos["sousTitre"]],
              parseInt(cellules[pos["ordre"]]),
              parseInt(cellules[pos["PDLFond"]]),
              parseInt(cellules[pos["PDLPoint"]]),
              this._versionManager.getVersion(
                parseInt(cellules[pos["vCreation"]])
              ),
              this._versionManager.getVersion(parseInt(cellules[pos["vMaj"]])),
              this._versionManager.getVersion(
                parseInt(cellules[pos["vSuppression"]])
              )
            )
          );
        }
        i++;
      }
      return region;
    });
  }

  public async load(region: Region): Promise<Region> {
    //On doit charger fichier par fichier

    //Lignes.csv
    return this.readLigneFile(region)
      .then((region) => this.readGareFile(region))
      .then((region) => this.readCoordonneesFile(region))
      .then((region) => this.readGaresDansLigneFile(region))
      .then((region) => {
        region.loaded = true;
        return region;
      });
  }
}
