import { Coordonnees } from "./Coordonnees";
import { Couleur } from "./Couleur";
import Version from "./Version";
import { VersionnedItem } from "./VersionnedItem";

export default class Gare extends VersionnedItem {
  id: string;
  idExterne: string;
  nom: string;
  exploitant: string;
  latitude: number;
  longitude: number;
  couleur: Couleur;
  couleurEvolution: Couleur;
  coordonnees: Array<Coordonnees>;

  constructor(
    id: string,
    idExterne: string,
    nom: string,
    exploitant: string,
    latitude: number,
    longitude: number,
    couleur: Couleur,
    couleurEvolution: Couleur,
    vCreation: Version,
    vMaj: Version,
    vSuppression: Version
  ) {
    super(vCreation, vMaj, vSuppression);
    this.id = id;
    this.idExterne = idExterne;
    this.nom = nom;
    this.exploitant = exploitant;
    this.latitude = latitude;
    this.longitude = longitude;
    this.couleur = couleur;
    this.couleurEvolution = couleurEvolution;
    this.coordonnees = [];
  }

  public addCoordonnees(coordonnees: Coordonnees) {
    this.coordonnees.push(coordonnees);
  }
}
