import Version from "./Version";
import GareDansLigne from "./GareDansLigne";
import Gare from "./Gare";
import Ligne from "./Ligne";
import { TypeLigne } from "./TypeLigne";
import { Couleur } from "./Couleur";
import { ExportedData } from "./ExportedData";
import { VersionnedItem } from "./VersionnedItem";

export default class Region extends VersionnedItem {
  id: number;
  nom: string;
  dossierId: string;
  public loaded: boolean;
  gares: Array<Gare>;
  lignes: Array<Ligne>;
  garesdansligne: Array<GareDansLigne>;

  constructor(id: number, nom: string, dossierId: string, vCreation: Version, vMaj: Version) {
    super(vCreation, vMaj, null); //une region ne peut pas être supprimée
    this.id = id;
    this.nom = nom;
    this.dossierId = dossierId;

    this.loaded = false;

    this.gares = new Array<Gare>();
    this.lignes = new Array<Ligne>();
    this.garesdansligne = new Array<GareDansLigne>();
  }

  public addLigne(ligne: Ligne) {
    this.lignes.push(ligne);
  }

  public addGare(gare: Gare) {
    this.gares.push(gare);
  }

  public addGaresDansLigne(gdl: GareDansLigne) {
    if (this.garesdansligne.find((item: GareDansLigne) => gdl.gare === item.gare && gdl.ligne === item.ligne) !== undefined) {
      //Pas de doublons !
      console.log("Doublon !");
      return;
    }

    this.garesdansligne.push(gdl);
  }

  public getLigne(idLigne: string): Ligne | undefined {
    return this.lignes.find((l) => l.idExterne === idLigne);
  }

  public getGare(idGare: string): Gare | undefined {
    return this.gares.find((g) => g.id === idGare);
  }

  public generateExportData(nouveauNumeroVersion: number, selectedLignes?: Array<Ligne>): ExportedData {
    return {
      fileGares: this.exportGares(nouveauNumeroVersion, selectedLignes),
      fileLignes: this.exportLignes(nouveauNumeroVersion, selectedLignes),
      fileGaresDansLigne: this.exportGdl(nouveauNumeroVersion, selectedLignes),
    };
  }

  private getNumeroVersion(version: Version | null, nouveauNumeroVersion: number): number {
    if (version === null) return nouveauNumeroVersion;

    if (version.existe) return version.numero;

    return nouveauNumeroVersion;
  }

  private exportLignes(nouveauNumeroVersion: number, selectedLignes?: Array<Ligne>): string {
    return (
      "idExterne;nom;type;ordre;couleur;vCreation;vMaj;vSuppression" +
      "\r\n" +
      this.lignes
        .filter((ligne) => !selectedLignes || selectedLignes.indexOf(ligne) !== -1)
        .map(
          (item: Ligne) =>
            item.idExterne +
            ";" +
            item.nom +
            ";" +
            TypeLigne[item.type] +
            ";" +
            item.ordre +
            ";" +
            item.couleur +
            ";" +
            this.getNumeroVersion(item.versionCreation, nouveauNumeroVersion) +
            ";" +
            this.getNumeroVersion(item.versionMaj, nouveauNumeroVersion) +
            ";" +
            this.getNumeroVersion(item.versionSuppression, nouveauNumeroVersion)
        )
        .join("\n")
    );
  }

  private exportGares(nouveauNumeroVersion: number, selectedLignes?: Array<Ligne>): string {
    let selectedGares: Array<Gare> = this.gares;
    if (selectedLignes) {
      const gdl = this.garesdansligne.filter((item) => !selectedLignes || selectedLignes.indexOf(item.ligne) !== -1);
      const gares = gdl.map((item) => item.gare);
      selectedGares = gares.filter((item, pos) => gares.indexOf(item) === pos);
    }
    return (
      "idExterne;nom;exploitant;latitude;longitude;couleur;couleurEvolution;vCreation;vMaj;vSuppression" +
      "\r\n" +
      selectedGares
        .map(
          (item: Gare) =>
            item.idExterne +
            ";" +
            item.nom +
            ";" +
            item.exploitant +
            ";" +
            item.latitude +
            ";" +
            item.longitude +
            ";" +
            Couleur[item.couleur] +
            ";" +
            Couleur[item.couleurEvolution] +
            ";" +
            this.getNumeroVersion(item.versionCreation, nouveauNumeroVersion) +
            ";" +
            this.getNumeroVersion(item.versionMaj, nouveauNumeroVersion) +
            ";" +
            this.getNumeroVersion(item.versionSuppression, nouveauNumeroVersion)
        )
        .join("\n")
    );
  }

  private exportGdl(nouveauNumeroVersion: number, selectedLignes?: Array<Ligne>): string {
    return (
      "idGare;idLigne;nom;surTitre;sousTitre;ordre;PDLFond;PDLPoint;vCreation;vMaj;vSuppression" +
      "\r\n" +
      this.garesdansligne
        .filter((item) => !selectedLignes || selectedLignes.indexOf(item.ligne) !== -1)
        .map(
          (item: GareDansLigne) =>
            item.gare.id +
            ";" +
            item.ligne.idExterne +
            ";" +
            (!item.nom ? "" : item.nom) +
            ";" +
            (!item.surTitre ? "" : item.surTitre) +
            ";" +
            (!item.sousTitre ? "" : item.sousTitre) +
            ";" +
            item.ordre +
            ";" +
            item.pdlFond +
            ";" +
            item.pdlPoint +
            ";" +
            this.getNumeroVersion(item.versionCreation, nouveauNumeroVersion) +
            ";" +
            this.getNumeroVersion(item.versionMaj, nouveauNumeroVersion) +
            ";" +
            this.getNumeroVersion(item.versionSuppression, nouveauNumeroVersion)
        )
        .join("\n")
    );
  }
}
