import React from "react";
import Gare from "../../../structure/Gare";
import GareDansLigneItem from "./GareDansLigneItem";
import autobind from "autobind-decorator";
import Ligne from "../../../structure/Ligne";
import { TypeActionRegion } from "../../ActionRegion";
import GareDansLigne from "../../../structure/GareDansLigne";
import { orderByValue } from "../../../Tools";
import { CarteDesGaresDansLigne } from "./CarteDesGaresDansLigne";
import {
  AfficherGaresCommunProps,
  AfficherGaresCommun,
} from "./AfficherGaresCommun";

interface AfficherGaresDansLigneProps extends AfficherGaresCommunProps {
  gares: Array<GareDansLigne>;
  currentLigne: Ligne | null;
  refreshListGares: (ligne: Ligne) => () => void;
}

export default class AfficherGaresDansLigne extends AfficherGaresCommun<AfficherGaresDansLigneProps> {
  public constructor(props: AfficherGaresDansLigneProps) {
    super(
      props,
      props.region.lignes.filter(
        (ligne: Ligne) =>
          props.currentLigne !== null &&
          ligne.idExterne !== props.currentLigne.idExterne
      )[0]
    );
  }

  @autobind
  ordonnerParPosition(event: React.FormEvent<HTMLButtonElement>): void {
    event.preventDefault();

    if (this.props.currentLigne === null) return;

    this.props.dispatcherRegion({
      type: TypeActionRegion.ordonnerParPosition,
      ligne: this.props.currentLigne,
    });

    this.props.refreshListGares(this.props.currentLigne)();
  }

  @autobind
  faireThermometreSimple(event: React.FormEvent<HTMLButtonElement>): void {
    event.preventDefault();

    if (this.props.currentLigne === null) return;

    this.props.dispatcherRegion({
      type: TypeActionRegion.faireThermometreSimple,
      ligne: this.props.currentLigne,
    });

    this.props.refreshListGares(this.props.currentLigne)();
  }

  @autobind
  inverserOrdre(event: React.FormEvent<HTMLButtonElement>): void {
    event.preventDefault();

    if (this.props.currentLigne === null) return;

    this.props.dispatcherRegion({
      type: TypeActionRegion.inverserOrdre,
      ligne: this.props.currentLigne,
    });

    this.props.refreshListGares(this.props.currentLigne)();
  }

  @autobind
  retirerDeLigne(event: React.FormEvent<HTMLButtonElement>): void {
    event.preventDefault();

    if (this.props.currentLigne === null) return;

    let ligne = this.props.currentLigne;

    this.state.selectedGares.forEach((gare: Gare) =>
      this.props.dispatcherRegion({
        type: TypeActionRegion.supprimerGareDansLigne,
        ligne: ligne,
        gare: gare,
      })
    );

    this.setState({ selectedGares: [] }); //On vide

    this.props.refreshListGares(this.props.currentLigne)();
  }

  @autobind
  selectWithCorrespondances(event: React.FormEvent<HTMLButtonElement>): void {
    event.preventDefault();

    if (this.props.currentLigne === null) return;

    let ligne = this.props.currentLigne;

    //On commence par lister toutes les gares qui ont un autre gdl
    let withCorrespondances = this.props.region.garesdansligne
      .filter((gdl: GareDansLigne) => gdl.ligne.idExterne !== ligne.idExterne)
      .map((gdl: GareDansLigne) => gdl.gare.idExterne);

    console.log(withCorrespondances);

    //Puis, on parcours la liste
    let newSelectedGares = this.props.gares
      .filter(
        (gdl: GareDansLigne) =>
          withCorrespondances.indexOf(gdl.gare.idExterne) !== -1
      )
      .map((gdl: GareDansLigne) => gdl.gare);

    console.log(newSelectedGares);

    this.setState({
      ...this.state,
      selectedGares: newSelectedGares,
    });
  }

  @autobind
  toggleAllCheckbox() {
    super.toggleAllCheckbox(
      this.props.gares.map((gdl: GareDansLigne) => gdl.gare)
    );
  }

  @autobind
  changeAfficherZoneTampon(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      ...this.state,
      afficherZoneTampon: event.target.checked,
    });
  }

  render() {
    return (
      <div>
        <CarteDesGaresDansLigne
          gares={this.props.gares}
          afficherZoneTampon={this.state.afficherZoneTampon}
        />
        <div>
          <label>
            <input
              type="checkbox"
              checked={this.state.afficherZoneTampon}
              onChange={this.changeAfficherZoneTampon}
            />{" "}
            Afficher zone tamponage
          </label>
        </div>
        <h2>
          Gares de la ligne{" "}
          {this.props.currentLigne !== null
            ? this.props.currentLigne.nom
            : "<Inconnu>"}{" "}
        </h2>
        <div>
          <button onClick={this.selectWithCorrespondances}>
            Arrêts avec correspondances
          </button>{" "}
          |<button onClick={this.retirerDeLigne}>Retirer de la ligne</button> |
          {this.afficherBoutonCommun(
            this.props.region.lignes.filter(
              (ligne: Ligne) =>
                this.props.currentLigne !== null &&
                ligne.idExterne !== this.props.currentLigne.idExterne
            )
          )}
          <button onClick={this.ordonnerParPosition}>
            Ordonner par position
          </button>
          <button onClick={this.faireThermometreSimple}>
            Thermomètre simple
          </button>
          <button onClick={this.inverserOrdre}>Inverser l'ordre</button>
        </div>
        <table>
          <thead>
            <tr>
              <th>
                <input type="checkbox" onChange={this.toggleAllCheckbox} />
              </th>
              <th>Nom</th>
              <th>Ordre</th>
              <th>Point</th>
            </tr>
          </thead>
          <tbody>
            {this.props.gares
              .sort((a: GareDansLigne, b: GareDansLigne) =>
                orderByValue(a.ordre, b.ordre)
              )
              .map((gdl: GareDansLigne) => (
                <GareDansLigneItem
                  key={gdl.gare.idExterne}
                  gdl={gdl}
                  onSelectedGare={this.onSelectedGare}
                  selected={this.state.selectedGares.indexOf(gdl.gare) !== -1}
                  region={this.props.region}
                  dispatcherRegion={this.props.dispatcherRegion}
                />
              ))}
          </tbody>
        </table>
      </div>
    );
  }
}
