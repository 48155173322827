import React from "react";
import { Point } from "../../../../structure/PointDansLigne";

export type SelecteurPointProps = {
  point: Point;
  onChange: (point: Point) => void;
};

export default function SelecteurPoint(props: SelecteurPointProps) {
  const changePoint = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.stopPropagation();

    const newPoint = parseInt(event.target.value) as Point;
    props.onChange(newPoint);
  };

  const getListePoint = (): Array<Point> => {
    return [
      Point.Vide,
      Point.Centre,
      Point.CentreSensBas,
      Point.CentreSensHaut,
      Point.Droite,
      Point.DroiteSensBas,
      Point.DroiteSensHaut,
      Point.Gauche,
      Point.GaucheSensBas,
      Point.GaucheSensHaut,
    ];
  };

  return (
    <select onChange={changePoint} defaultValue={props.point}>
      {getListePoint().map((point) => (
        <option value={point} key={point.toLocaleString()}>
          {Point[point]}
        </option>
      ))}
    </select>
  );
}
