import Ligne from "../structure/Ligne";
import { IVersionManager } from "./VersionManager";
import GareDansLigne from "../structure/GareDansLigne";
import { Fond, Point } from "../structure/PointDansLigne";
import Gare from "../structure/Gare";

export class GareDansLigneFactory {
  private _versionManager: IVersionManager;
  public constructor(versionManager: IVersionManager) {
    this._versionManager = versionManager;
  }

  public generate(gare: Gare, ligne: Ligne): GareDansLigne {
    let garedansLigne = new GareDansLigne(
      gare,
      ligne,
      null,
      null,
      null,
      0,
      Fond.Vide,
      Point.Vide,
      this._versionManager.getWorkspaceVersion(),
      this._versionManager.getNoneVersion(),
      this._versionManager.getNoneVersion()
    );
    return garedansLigne;
  }
}
