import { Component } from "react";
import { Fond, Point } from "../../../../structure/PointDansLigne";
import React from "react";

interface AfficherPointProps {
  fond: Fond;
  point: Point;
  couleur: string;
}

export default class AfficherPoint extends Component<AfficherPointProps> {
  render() {
    const GaucheHaut =
      this.props.fond === Fond.GaucheHaut ||
      this.props.fond === Fond.GaucheDroiteHautCentreBas ||
      this.props.fond === Fond.GaucheDroiteHautGaucheBas ||
      this.props.fond === Fond.GaucheDroitePetitMilieuHautDroiteBas ||
      this.props.fond === Fond.GaucheDroiteHautGaucheDroiteBas ||
      this.props.fond === Fond.GaucheHautGaucheDroiteBas ||
      this.props.fond === Fond.GaucheDroiteHaut ||
      this.props.fond === Fond.GaucheHautBas ||
      this.props.fond === Fond.GaucheDroiteHautDroiteBas ||
      this.props.fond === Fond.GaucheDroiteHautDroitePetitMilieuBas ||
      this.props.fond === Fond.GaucheDroiteHautCentreMilieuGaucheDroiteBas;

    const CentreHaut =
      this.props.fond === Fond.CentreHaut ||
      this.props.fond === Fond.CentreHautBas ||
      this.props.fond === Fond.CentreHautGaucheDroiteBas ||
      this.props.fond === Fond.CentreHautGaucheBas;

    const DroiteHaut =
      this.props.fond === Fond.DroiteHaut ||
      this.props.fond === Fond.GaucheDroiteHautCentreBas ||
      this.props.fond === Fond.GaucheDroiteHautGaucheBas ||
      this.props.fond === Fond.GaucheDroitePetitMilieuHautDroiteBas ||
      this.props.fond === Fond.GaucheDroiteHautGaucheDroiteBas ||
      this.props.fond === Fond.DroiteHautGaucheDroiteBas ||
      this.props.fond === Fond.GaucheDroiteHaut ||
      this.props.fond === Fond.DroiteHautBas ||
      this.props.fond === Fond.GaucheDroiteHautDroiteBas ||
      this.props.fond === Fond.GaucheDroiteHautDroitePetitMilieuBas ||
      this.props.fond === Fond.DroiteHautCentreBas ||
      this.props.fond === Fond.GaucheDroiteHautCentreMilieuGaucheDroiteBas;

    const FourcheHautGauche =
      this.props.fond === Fond.GaucheDroiteHaut ||
      this.props.fond === Fond.GaucheDroiteHautCentreBas ||
      this.props.fond === Fond.GaucheDroitePetitMilieuHautDroiteBas ||
      this.props.fond === Fond.GaucheDroiteHautCentreMilieuGaucheDroiteBas;

    const FourcheHautDroite =
      this.props.fond === Fond.GaucheDroiteHaut ||
      this.props.fond === Fond.GaucheDroiteHautCentreBas ||
      this.props.fond === Fond.DroiteHautCentreBas ||
      this.props.fond === Fond.GaucheDroiteHautCentreMilieuGaucheDroiteBas;

    const GaucheBas =
      this.props.fond === Fond.GaucheBas ||
      this.props.fond === Fond.CentreHautGaucheDroiteBas ||
      this.props.fond === Fond.GaucheHautGaucheDroiteBas ||
      this.props.fond === Fond.GaucheDroiteHautGaucheDroiteBas ||
      this.props.fond === Fond.DroiteHautGaucheDroiteBas ||
      this.props.fond === Fond.GaucheDroiteBas ||
      this.props.fond === Fond.GaucheHautBas ||
      this.props.fond === Fond.GaucheDroiteHautGaucheBas ||
      this.props.fond === Fond.CentreHautGaucheBas ||
      this.props.fond === Fond.GaucheDroiteHautCentreMilieuGaucheDroiteBas;

    const CentreBas =
      this.props.fond === Fond.CentreBas ||
      this.props.fond === Fond.CentreHautBas ||
      this.props.fond === Fond.GaucheDroiteHautCentreBas ||
      this.props.fond === Fond.DroiteHautCentreBas;

    const DroiteBas =
      this.props.fond === Fond.DroiteBas ||
      this.props.fond === Fond.CentreHautGaucheDroiteBas ||
      this.props.fond === Fond.GaucheHautGaucheDroiteBas ||
      this.props.fond === Fond.GaucheDroiteHautGaucheDroiteBas ||
      this.props.fond === Fond.GaucheDroiteHautDroiteBas ||
      this.props.fond === Fond.GaucheDroiteHautDroitePetitMilieuBas ||
      this.props.fond === Fond.GaucheDroitePetitMilieuHautDroiteBas ||
      this.props.fond === Fond.GaucheDroiteBas ||
      this.props.fond === Fond.DroiteHautBas ||
      this.props.fond === Fond.DroiteHautGaucheDroiteBas ||
      this.props.fond === Fond.GaucheDroiteHautCentreMilieuGaucheDroiteBas;

    const FourcheBasGauche =
      this.props.fond === Fond.GaucheDroiteBas ||
      this.props.fond === Fond.CentreHautGaucheDroiteBas ||
      this.props.fond === Fond.CentreHautGaucheBas ||
      this.props.fond === Fond.GaucheDroiteHautCentreMilieuGaucheDroiteBas ||
      this.props.fond === Fond.GaucheDroiteHautDroitePetitMilieuBas;

    const FourcheBasDroite =
      this.props.fond === Fond.GaucheDroiteBas ||
      this.props.fond === Fond.CentreHautGaucheDroiteBas ||
      this.props.fond === Fond.GaucheDroiteHautCentreMilieuGaucheDroiteBas;

    const PointGauche =
      this.props.point === Point.Gauche ||
      this.props.point === Point.GaucheSensBas ||
      this.props.point === Point.GaucheSensHaut;
    const PointCentre =
      this.props.point === Point.Centre ||
      this.props.point === Point.CentreSensBas ||
      this.props.point === Point.CentreSensHaut;
    const PointDroite =
      this.props.point === Point.Droite ||
      this.props.point === Point.DroiteSensBas ||
      this.props.point === Point.DroiteSensHaut;
    const PointSensHaut =
      this.props.point === Point.GaucheSensHaut ||
      this.props.point === Point.CentreSensHaut ||
      this.props.point === Point.DroiteSensHaut;
    const PointSensBas =
      this.props.point === Point.GaucheSensBas ||
      this.props.point === Point.CentreSensBas ||
      this.props.point === Point.DroiteSensBas;

    const PetitPointMilieuHaut =
      this.props.fond === Fond.GaucheDroitePetitMilieuHautDroiteBas;
    const PetitPointMilieuBas =
      this.props.fond === Fond.GaucheDroiteHautDroitePetitMilieuBas;

    const couleur =
      this.props.couleur === "#000000" ? "#FFFFFF" : this.props.couleur;

    return (
      <pre className="schema" style={{ color: couleur }}>
        {GaucheHaut ? "|" : " "}  {" "}
        {CentreHaut ? "|" : PetitPointMilieuHaut ? "■" : " "}  {" "}
        {DroiteHaut ? "|" : " "}
        <br />
        {GaucheHaut ? "|" : " "} {FourcheHautGauche ? "−" : " "}{" "}
        {CentreHaut ? "|" : PetitPointMilieuHaut ? "|" : " "}{" "}
        {FourcheHautDroite ? "−" : " "} {DroiteHaut ? "|" : " "}
        <br />
        {PointGauche ? "•" : " "}{" "}
        {PointSensHaut ? "↑" : PointSensBas ? "↓" : " "}{" "}
        {PointCentre ? "•" : " "}{" "}
        {PointSensHaut ? "↑" : PointSensBas ? "↓" : " "}{" "}
        {PointDroite ? "•" : " "}
        <br />
        {GaucheBas ? "|" : " "} {FourcheBasGauche ? "−" : " "}{" "}
        {CentreBas ? "|" : PetitPointMilieuBas ? "|" : " "}{" "}
        {FourcheBasDroite ? "−" : " "} {DroiteBas ? "|" : " "}
        <br />
        {GaucheBas ? "|" : " "}  {" "}
        {CentreBas ? "|" : PetitPointMilieuBas ? "■" : " "}  {" "}
        {DroiteBas ? "|" : " "}
      </pre>
    );
  }
}
