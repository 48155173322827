import React from "react";
import autobind from "autobind-decorator";
import GareDansLigne from "../../../structure/GareDansLigne";
import AfficherPoint from "./PlanDeLigne/AfficherPoint";
import { TypeActionRegion } from "../../ActionRegion";
import { GareItemCommunProps, GareItemCommun } from "./GareItemCommun";
import { Fond, Point } from "../../../structure/PointDansLigne";
import SelecteurFond from "./PlanDeLigne/SelecteurFond";
import SelecteurPoint from "./PlanDeLigne/SelecteurPoint";

interface GareDansLigneItemProps extends GareItemCommunProps {
  gdl: GareDansLigne;
}

export default class GareDansLigneItem extends GareItemCommun<GareDansLigneItemProps> {
  @autobind
  selectGare() {
    this.props.onSelectedGare(this.props.gdl.gare, !this.props.selected);
  }

  @autobind
  changeOrder(event: React.ChangeEvent<HTMLInputElement>) {
    event.stopPropagation();

    let newGdl: GareDansLigne = this.props.gdl.withOrdre(
      parseInt(event.target.value)
    );

    this.props.dispatcherRegion({
      type: TypeActionRegion.modifierGareDansLigne,
      gareDansLigne: newGdl,
    });
  }

  @autobind
  changeFond(fond: Fond) {
    let newGdl: GareDansLigne = this.props.gdl.withFond(fond);

    this.props.dispatcherRegion({
      type: TypeActionRegion.modifierGareDansLigne,
      gareDansLigne: newGdl,
    });
  }

  @autobind
  changePoint(point: Point) {
    let newGdl: GareDansLigne = this.props.gdl.withPoint(point);

    this.props.dispatcherRegion({
      type: TypeActionRegion.modifierGareDansLigne,
      gareDansLigne: newGdl,
    });
  }

  render() {
    return (
      <tr>
        <th>
          <input
            type="checkbox"
            checked={this.props.selected}
            onChange={this.selectGare}
          />
        </th>
        <td>
          {!this.props.gdl.nom || this.props.gdl.nom === ""
            ? this.props.gdl.gare.nom
            : this.props.gdl.nom}
        </td>
        <td>
          <input
            className="order"
            type="number"
            value={this.props.gdl.ordre}
            onChange={this.changeOrder}
          />
        </td>
        <td className="schema-cellule">
          <AfficherPoint
            fond={this.props.gdl.pdlFond}
            point={this.props.gdl.pdlPoint}
            couleur={this.props.gdl.ligne.couleur}
          />
          <div className="schema-select-area">
            <SelecteurFond
              fond={this.props.gdl.pdlFond}
              onChange={this.changeFond}
            />
            <SelecteurPoint
              point={this.props.gdl.pdlPoint}
              onChange={this.changePoint}
            />
          </div>
        </td>
      </tr>
    );
  }
}
